import { Pngs } from "res";
import styles from "./AppDownloadButtons.module.css";

const PLAY_STORE_LINK =
  "https://play.google.com/store/apps/details?id=ai.snapshelf";
const APP_STORE_LINK =
  "https://apps.apple.com/pl/app/snapshelf-ai/id6476834174";

interface Props {
  variant?: "default" | "small";
  style?: string;
}

const AppDownloadButtons = ({ variant = "default", style }: Props) => {
  const isSmall = variant === "small";
  const finalStyle = `${styles.container} ${style}`;
  const finalButtonStyle = `${styles.button} ${isSmall && styles.buttonSmall}`;

  const onAppStoreClick = () => {
    window.open(APP_STORE_LINK, "_blank");
  };

  const onPlayStoreClick = () => {
    window.open(PLAY_STORE_LINK, "_blank");
  };

  return (
    <div className={finalStyle}>
      <img
        className={finalButtonStyle}
        src={Pngs.AppStoreLogo}
        alt=""
        onClick={onAppStoreClick}
      />
      <img
        className={`${finalButtonStyle} ${styles.buttonMarginLeft}`}
        src={Pngs.GooglePlayLogo}
        alt=""
        onClick={onPlayStoreClick}
      />
    </div>
  );
};

export default AppDownloadButtons;
